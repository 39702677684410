// Rester en ES5 ici sinon le build plantera.
// Gatsby-node.js non compatible es6 : https://github.com/gatsbyjs/gatsby/issues/7810

module.exports.validDimension = (node, largeur, hauteur) => {
  largeur = parseFloat(largeur)
  hauteur = parseFloat(hauteur)
  if (largeur < node.ba_mini || hauteur < node.ba_mini) return false
  if (largeur > node.ba_maxi || hauteur > node.ba_maxi) return false
  if (largeur * 2 + hauteur * 2 > node.ba_perimetre_maxi) return false
  return true
}

module.exports.getSizesMax = (products) => {
  return products.reduce(
    (acc, cur) => {
      if (cur.ba_maxi > acc.ba_maxi) {
        acc.ba_maxi = cur.ba_maxi
      }
      if (cur.ba_perimetre_maxi > acc.ba_perimetre_maxi) {
        acc.ba_perimetre_maxi = cur.ba_perimetre_maxi
      }
      return acc
    },
    { ba_perimetre_maxi: 0, ba_maxi: 0 }
  )
}

module.exports.getFilterMaxis = (e, tirage) => {
  if (tirage === "sublimation") {
    return { ba_perimetre_maxi: e.tirage_sublimation_perimetre_maxi, ba_maxi: e.tirage_sublimation_ba_maxi }
  }
  if (["tirage", "tirage_haut_de_gamme"].includes(tirage)) {
    return { ba_perimetre_maxi: e.tirage_perimetre_maxi, ba_maxi: e.tirage_ba_maxi }
  }
  return { ba_perimetre_maxi: e.ba_perimetre_maxi, ba_maxi: e.ba_maxi }
}
module.exports.filterTirage = (e, tirage) => {
  return (tirage == "tirage_sublimation" && e.tirage_sublimation_ba_maxi > 0) || (["tirage", "tirage_haut_de_gamme"].includes(tirage) && e.tirage_ba_maxi > 0)
}

module.exports.dimensions = process.env.NODE_ENV === "development" ? 
  [
    [50, 70],
    [21, 29.7, "A4"],
  ] :
  [
    [50, 70],
    [70, 100],
    [40, 50],
    [30, 40],
    [60, 90],
    [20, 30],
    [21, 30],
    [30, 45],
    [60, 80],
    [30, 30],
    [15, 20],
    [50, 50],
    [15, 21, "A5"],
    [21, 29.7, "A4"],
    [29.7, 42, "A3"],
    [42, 59.4, "A2"],
    [59.4, 84.1, "A1"],
    [84.1, 118.9, "A0"],
    [27, 22, "3F"],
    [33, 24, "4F"],
    [35, 27, "5F"],
    [41, 33, "6F"],
    [46, 38, "8F"],
    [55, 46, "10F"],
    [61, 50, "12F"],
    [65, 54, "15F"],
    [73, 60, "20F"],
    [81, 65, "25F"],
    [92, 73, "30F"],
    [41, 27, "6P"],
    [46, 33, "8P"],
    [55, 38, "10P"],
    [61, 46, "12P"],
    [65, 50, "15P"],
    [73, 54, "20P"],
    [92, 65, "30P"],
  ]

module.exports.productsPerPage = 36
module.exports.regroupementsCategories = [
  {
    title: "Les cadres aluminium",
    categs: [1],
    order2: 2,
  },
  { title: "Les cadres bois", categs: [5, 2, 6, 7], order2: 1 },
  { title: "Les exclusifs", categs: [8, 9, 11, 12, 13], order2: 4 },
]

module.exports.topVentes = [
  { categ: 1, famille: "NATURA 25.50", cog_id: 9 },
  { categ: 3, famille: "OXYTON 2", cog_id: 3 },
  { categ: 4, famille: "PLANA STANDING", cog_id: 10 },
  { categ: 5, famille: "BROSSERA 26", cog_id: 2 },
  { categ: 6, famille: "ALU 120", cog_id: 10 },
  { categ: 7, famille: "DAVIS", cog_id: 7 },
  { categ: 8, famille: "EMPERIA", cog_id: 1 },
  { categ: 9, famille: "LUPUS 40", cog_id: 9 },
  { categ: 10, famille: "ELLA", cog_id: 10 },
  { categ: 12, famille: "MAJOR", cog_id: 10 },
  { categ: 13, famille: "RAPHAELLO", cog_id: null },
  { categ: 14, famille: "PRESTY CHIC", cog_id: 1 },
  { categ: 15, famille: "ADANA", cog_id: 3 },
  { categ: 16, famille: "Entre 2 verres", cog_id: null },
  { categ: 17, famille: "ASTRAL", cog_id: null },
  { categ: 18, famille: "SPINOSA", cog_id: 1 },
  { categ: 19, famille: "ATLANTA", cog_id: 2 },
  { categ: 20, famille: "PLANA 40.16", cog_id: null },
  { categ: 21, famille: "LOUISIANE", cog_id: 4 },
]

module.exports.kitMontage = 18
module.exports.services_rehausse = ["precoupe", "coupe_assemble", "coupe_assemble_isorel", "verre_normal", "verre_invisible", "plexi_normal"]



module.exports.groupByColor = (products, color) => {
  let familleProduct = []
  let familleProductExist = []
  products
    .sort((a, b) => a.ba_continue - b.ba_continue)
    .map((node) => {
      if (!familleProductExist.includes(node.cat_id + "-" + node.fa_id + "-" + node.ba_libelle_france_cadre) && (!color || color === node.cog_id)) {
        familleProductExist.push(node.cat_id + "-" + node.fa_id + "-" + node.ba_libelle_france_cadre)
        familleProduct.push(node)
      }
    })
  return familleProduct
}

module.exports.bordureMin = 2

module.exports.checkPpDimensions = (pp) => {
  return (
    pp.margeG >= this.bordureMin &&
    pp.margeG <= 50 &&
    pp.margeD >= this.bordureMin &&
    pp.margeD <= 50 &&
    pp.margeH >= this.bordureMin &&
    pp.margeH <= 50 &&
    pp.margeB >= this.bordureMin &&
    pp.margeB <= 50 &&
    (pp.ouvertures[0] === 1 || (pp.ouvertures[0] > 1 && pp.interL >= this.bordureMin)) &&
    (pp.ouvertures[1] === 1 || (pp.ouvertures[1] > 1 && pp.interH >= this.bordureMin))
  )
}

module.exports.regroupementCatFC = {
  1: { title: "Cadre aluminium", subtitle: "", categs: [6], img: "alu", order: 1 },
  2: { title: "Cadre bois", subtitle: "", categs: [4, 5, 7, 8, 9, 14, 15, 20], img: "bois", order: 2 },
  5: { title: "Caisse américaine", subtitle: "", categs: [3, 12, 10, 18], img: "caisse", order: 6 },
  6: { title: "Cadre avec Marie Louise", subtitle: "", categs: [13, 21, 19], img: "marielouiseintegree", order: 3 },
  7: { title: "Cadre vitrine", subtitle: "Option réhausse", categs: [1], img: "vitrine", order: 7 },
  8: { title: "Cadre entre deux verres", subtitle: "", categs: [16], img: "entredeuxverres", order: 8 },
  9: { title: "Cadre astral", subtitle: "Support rigide, dibon, etc", categs: [17], img: "astral", order: 9 },
  11: { title: "Cadre doublo design", subtitle: "", categs: [20], img: null },
  12: { title: "Cadre doublo caisse américaine", subtitle: "", categs: [18], img: null },
  13: { title: "Cadre doublo marie louise", subtitle: "", categs: [19], img: null },
}
