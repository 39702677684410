import React, { useEffect } from "react"
import Layout from "../components/layout"
import PropTypes from "prop-types"
import { Link } from "gatsby-plugin-spinner"
import { useDispatch, useSelector } from "react-redux"
import SEO from "./../components/seo"

import { filterTirage, getFilterMaxis, regroupementCatFC, regroupementsCategories } from "../services/cadre"
import { getSizeInfos } from "../profcadresprix/index-legacy"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight, faUndoAlt } from "@fortawesome/free-solid-svg-icons"
import { categsNoPasse, getOptions } from "@bko/optionsproduits"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"
import { setPpType } from "../actions/passe"
import { resetPageProduct, setDimensionsDefault, setIsorel } from "../actions/cadre"
import { setPrevisuPhoto } from "../actions/photos"

const CategoriesPage = (props) => {
  const pageProduit = useSelector((s) => s.PageProduit)
  const photosR = useSelector((s) => s.photosR)
  useEffect(() => {
    if (pageProduit.pp.setFrom === "passe") {
      dispatch(setPpType(null))
    }
  }, [])
  const data = useStaticQuery(graphql`
    query CategsQuery {
      allFile(filter: { relativePath: { regex: "/^categories/" } }) {
        nodes {
          name
          relativePath
          childImageSharp {
            gatsbyImageData(width: 200)
          }
        }
      }
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `)

  const pc = props.pageContext
  const filterSizes = (e) => {
    return (
      (getSizeInfos(pageProduit).perimetreIntra <= getFilterMaxis(e, photosR.tirage).ba_perimetre_maxi &&
        getSizeInfos(pageProduit).largeurIntra <= getFilterMaxis(e, photosR.tirage).ba_maxi &&
        getSizeInfos(pageProduit).hauteurIntra <= getFilterMaxis(e, photosR.tirage).ba_maxi) ||
      ([null, "aucun"].includes(pageProduit.pp.type) &&
        getSizeInfos(pageProduit).perimetreIntra <= 600 &&
        getSizeInfos(pageProduit).largeurIntra <= 150 &&
        getSizeInfos(pageProduit).hauteurIntra <= 150)
    )
  }
  let ppBool = true
  if (pageProduit.pp.type == "aucun" || pageProduit.pp.type == null) {
    ppBool = false
  }
  let items = []
  let categories = Object.values(pc.filtersData.categories).filter(
    (categ) =>
      ((ppBool && !categ.cat_ids_originales.every(v => [3, 16, 17].indexOf(v) >= 0) && photosR.tirage == null) || filterTirage(categ, photosR.tirage) || (!ppBool && photosR.tirage == null)) &&
      categ.libelle !== "Toutes" &&
      filterSizes(categ) &&
      ([null, "aucun"].includes(pageProduit.pp.type) || !categ.cat_ids_originales.every(v => categsNoPasse.indexOf(v) >= 0))
  )

  regroupementsCategories.map((edge) => {
    categories.filter((e) => edge.categs.includes(e.cat_id) && e.cat_id < 10).map((categ) => items.push({ categ, e: edge }))
  })

  const dispatch = useDispatch()
  const conditions = getOptions(
    { doublo_dispos: [] },
    pageProduit,
    useSelector((s) => s.photosR)
  )
  return (
    <>
      <Layout banner={false} location={props.location}>
        <SEO
          title={"Cadre sur mesure"}
          description="Découvrez tous nos cadres bois et aluminium, caisses américaines, cadres avec marie louise, exclusivités. Vos cadres sur mesures, assemblés à Besançon en France au meilleur prix"
          canonical={`${data.site.siteMetadata.siteUrl}/cadres/`}
          jsonLd={[
            {
              "@context": "https://schema.org",
              "@type": "BreadcrumbList",
              itemListElement: [
                {
                  "@type": "ListItem",
                  position: 1,
                  name: "Accueil",
                  item: data.site.siteMetadata.siteUrl,
                },
                {
                  "@type": "ListItem",
                  position: 2,
                  name: "Cadre sur mesure",
                  item: `${data.site.siteMetadata.siteUrl}/cadres/`,
                },
              ],
            },
          ]}
        />
        <nav className="breadcrumb mb-0 mt-4">
          <ul>
            <li>
              <Link to="/">Accueil</Link>
            </li>
            <li>
              <span className="pl-3">Cadre sur mesure</span>
            </li>
          </ul>
        </nav>

        <div className="column">
          {conditions.resetButton && (
            <div style={{ float: "right", marginTop: "2rem" }}>
              <span className="has-text-weight-bold mr-1">Filtres :</span>Cadres {pageProduit.largeur}x{pageProduit.hauteur}cm{" "}
              {pageProduit.pp && pageProduit.pp.type !== null && pageProduit.pp.type !== "aucun" ? "avec passe partout" : null}
              <button
                className="button is-secondary is-small is-warning ml-2"
                onClick={() => {
                  dispatch(setPpType(null))
                  dispatch(setIsorel(null))
                  dispatch(resetPageProduct())
                  dispatch(setPrevisuPhoto(null))
                  dispatch(setDimensionsDefault())
                }}
                data-cy="product-reinit"
              >
                <FontAwesomeIcon icon={faUndoAlt} className="mr-1" />
                Réinitialiser les filtres
              </button>
            </div>
          )}
          <h1 className="mt-0">Cadre sur mesure</h1>

          {items.length >= 1 ? (
            <div className="categsGrp differentsStyles">
              <div className="columns is-multiline is-centered is-vcentered is-variable" style={{ justifyContent: "space-around" }}>
                {items
                  .sort((a, b) => regroupementCatFC[a.categ.cat_id].order - regroupementCatFC[b.categ.cat_id].order)
                  .map((e) => {
                    return (
                      <div className="column is-4 categorie" key={e.categ.cat_id}>
                        <Link to={`/cadres/${e.categ.slug}/`} className="box">
                          <div className="columns is-mobile is-vcentered">
                            <div className="column is-narrow">
                              <GatsbyImage image={getImage(data.allFile.nodes.find((f) => f.name === regroupementCatFC[e.categ.cat_id].img))} alt={`${e.categ.cat_libelle} ${regroupementCatFC[e.categ.cat_id].subtitle}`} />
                            </div>
                            <div className="column pl-4" style={{ width: 160 }}>
                              <div className="h3" style={{ fontSize: "1.4em", marginBottom: "0.5rem" }}>{e.categ.cat_libelle}</div>
                              {(regroupementCatFC[e.categ.cat_id] && regroupementCatFC[e.categ.cat_id].subtitle !== "" && (
                                <span
                                  style={{
                                    fontSize: "1em",
                                    marginTop: "0.5rem",
                                    marginBottom: "1rem",
                                    background: "#ffca30",
                                    color: "#333",
                                    padding: 5,
                                  }}
                                >
                                  {regroupementCatFC[e.categ.cat_id].subtitle}
                                </span>
                              )) ||
                                null}
                              <div>
                                <div className="button is-small mt-2 is-primary is-outlined">
                                  <FontAwesomeIcon icon={faArrowRight} className="mr-2" /> Voir les collections
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    )
                  })}
              </div>
            </div>
          ) : null}
        </div>
      </Layout>
    </>
  )
}

CategoriesPage.propTypes = {
  location: PropTypes.object,
  pageContext: PropTypes.object,
}

export default CategoriesPage
